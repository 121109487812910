(function ($, window) {
  // console.log('foo');
  // console.log('bar');
  /**
   * Video Object Fit Emulator
   * @param {Element} video The video element to manipulate
   */
  function VideoObjectFit(el) {
    if (!(this instanceof VideoObjectFit)) return new VideoObjectFit(el);

    this.el = el;
    this.parent = el.parentElement;
    this.value = el.getAttribute('data-object-fit') || 'cover';
    this.position = el.getAttribute('data-position') || '50% 50%'.split(' ');

    if (!this.value) return false;

    this.el.style.opacity = 0;

    this.timer = null;
    this.canplayHandler = this.canplayHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);

    this.addPlaceholder();
    this.el.addEventListener('canplay', this.canplayHandler);
  }

  /**
   * Handler for the video's `canplay` handler
   * - Set the initial sizes
   * - Listen to the window resize event
   */
  VideoObjectFit.prototype.canplayHandler = function () {
    this.setSizes();
    window.addEventListener('resize', this.resizeHandler);
    this.el.removeEventListener('canplay', this.canplayHandler);
  };

  /**
   * Handler for the window's `resize` event that needs debounce
   */
  VideoObjectFit.prototype.resizeHandler = function () {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.setSizes.bind(this), 300);
  };

  /**
   * Set the sizes of the video
   */
  VideoObjectFit.prototype.setSizes = function () {
    this.sizes = this.getSizes();
    this.originalSizes = this.getOriginalSizes();
    const method = `get${this.ucFirst(this.value)}Sizes`;
    const sizes = this[method]();
    this.updatePlaceholder();
    this.el.style.position = 'absolute';

    // Vertical alignment
    if (this.position[0] === '0') {
      this.el.style.top = 0;
    } else if (this.position[0] === '100%') {
      this.el.style.top = 'auto';
      this.el.style.bottom = 0;
    } else if (this.position[0] === '50%') {
      this.el.style.top = '50%';
      this.el.style.marginTop = `${sizes.height / -2}px`;
    }

    // Horizontal alignment
    if (this.position[1] === '0') {
      this.el.style.left = 0;
    } else if (this.position[1] === '100%') {
      this.el.style.right = 0;
      this.el.style.left = 'auto';
    } else if (this.position[1] === '50%') {
      this.el.style.left = '50%';
      this.el.style.marginLeft = `${sizes.width / -2}px`;
    }

    this.el.style.width = `${sizes.width}px`;
    this.el.style.height = `${sizes.height}px`;
    this.el.style.opacity = '';
  };

  /**
   * Add a placeholder to fill the video space
   */
  VideoObjectFit.prototype.addPlaceholder = function () {
    this.placeholder = new Image();
    this.placeholder.style.display = 'block';
    this.placeholder.style.width = '100%';
    this.placeholder.style.height = 'auto';
    this.parent.appendChild(this.placeholder);
  };

  /**
   * Update the placeholder sizes
   */
  VideoObjectFit.prototype.updatePlaceholder = function () {
    const { width } = this.sizes;
    const { height } = this.sizes;
    const viewbox = ['0', '0', width, height].join(' ');
    this.placeholder.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewbox="${viewbox}"></svg>`;
  };

  /**
   * Get the target sizes
   * @return {Object} The target sizes
   */
  VideoObjectFit.prototype.getSizes = function () {
    this.el.style.width = '';
    this.el.style.height = '';
    return {
      width: this.el.offsetWidth,
      height: this.el.offsetHeight,
    };
  };

  /**
   * Get the video's original sizes
   * @return {Object} The video's original sizes
   */
  VideoObjectFit.prototype.getOriginalSizes = function () {
    return {
      width: this.el.videoWidth,
      height: this.el.videoHeight,
    };
  };

  /**
   * Get sizes for the "cover" option
   * @return {Object} The new sizes to set
   */
  VideoObjectFit.prototype.getCoverSizes = function () {
    let { width } = this.sizes;
    let { height } = this.sizes;

    const originalRatio = this.originalSizes.width / this.originalSizes.height;
    const ratio = this.sizes.width / this.sizes.height;

    if (originalRatio > ratio) {
      height = this.sizes.height + 2;
      width = (height * this.originalSizes.width) / this.originalSizes.height;
    } else {
      width = this.sizes.width + 2;
      height = (width * this.originalSizes.height) / this.originalSizes.width;
    }

    return {
      width,
      height,
    };
  };

  /**
   * Get sizes for the "contain" option
   * @return {Object} The new sizes to set
   */
  VideoObjectFit.prototype.getContainSizes = function () {
    let { width } = this.sizes;
    let { height } = this.sizes;

    const originalRatio = this.originalSizes.width / this.originalSizes.height;
    const ratio = this.sizes.width / this.sizes.height;

    if (originalRatio > ratio) {
      width = this.sizes.width - 30;
      height = (width * this.originalSizes.height) / this.originalSizes.width;
    } else {
      height = this.sizes.height - 30;
      width = (height * this.originalSizes.width) / this.originalSizes.height;
    }

    return {
      width,
      height,
    };
  };

  /**
   * Capitalize the first letter of a string
   * @param  {String} str The string to manipulate
   * @return {String}     The string with its first letter capitalized
   */
  VideoObjectFit.prototype.ucFirst = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  /* ===========================================================================
   * Auto instantiate the class
   * ======================================================================== */

  const instances = [];

  document.querySelectorAll('video[data-object-fit]').forEach(function (video) {
    instances.push(new VideoObjectFit(video));
  });
})(jQuery, window, document);
